// import Swiper from 'swiper';
// import Swiper from '../../node_modules/swiper/swiper-bundle.min.mjs';
// import { Pagination, Autoplay } from 'swiper/modules';

import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

let homeHandler = {
    init: function () {
        this.sliderFeatured();
        this.productsSliders();
    },
    sliderFeatured: function () {
        let sliderID = '#slider-homepage .slider';
        let $slider = new Swiper(sliderID, {
            wrapperClass: 'wrapper',
            slideClass: 'slide',
            slidesPerView: 1,
            // loop: true,
            modules: [Autoplay, Pagination],
            autoplay: {
                delay: 4500,
            },
            speed: 750,
            threshold: 20,
            pagination: {
                el: '#slider-homepage .slider-pagination',
                type: 'bullets',
                clickable: true,
                bulletActiveClass: 'active'
            },
            cssMode: true
        });

    },

    productsSliders: function () {

        let $sliders = document.querySelectorAll('.slider');

        if (!$sliders)
            return;

        Array.prototype.forEach.call($sliders, ($swiper) => {

            let $sliderSection = $swiper.closest('section');
            let sliderSectionID = $sliderSection.id;
            // console.log(sliderSectionID);

            // exclude featured home slider
            if (sliderSectionID === 'slider-homepage')
                return;

            let $slider = new Swiper($swiper, {
                wrapperClass: 'wrapper',
                slideClass: 'slide',
                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,
                // simulateTouch: false,
                modules: [Navigation],
                threshold: 20,
                breakpoints: {
                    600: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 3
                    },
                    980: {
                        slidesPerView: 4
                    },
                },
                navigation: {
                    prevEl: '#' + sliderSectionID + ' .slider-navigation .btn--slider-prev',
                    nextEl: '#' + sliderSectionID + ' .slider-navigation .btn--slider-next',
                    disabledClass: 'disabled'
                },
                cssMode: true
            });
        });
    },
};
if (document.querySelector('body.home')) {
    homeHandler.init();
}